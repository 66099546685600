<template>
  <div class="row">
    <div class="col-12 g">
        <div class="card card-body">
            <div class="col-12 table-responsive">
                <button class="btn btn-primary btn-sm" @click="print()">
                    <i class="fa fa-print"></i>
                    طباعة
                </button> &nbsp;
                <button class="btn btn-success btn-sm" @click="acceptAll()">
                    <i class="fa fa-check-square"></i>
                    الموافقة على الكل ({{ countPending() }})
                </button>
                &nbsp;
                <b-dropdown class="hidemonprint" dropbottom no-caret
                    id="dropdown-1" style="border-radius: 0px"
                    :html="`<i class='fa fa-whatsapp'></i> موافقة على الكل مع ارسال رسالة بالموافقة (${countPending()})` "
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="success"
                >
                    <b-dropdown-item @click="acceptAllWithMessage('wa')">
                        <i class="fa fa-whatsapp"></i>
                       اداة المتصفح
                    </b-dropdown-item>
                    <b-dropdown-item @click="acceptAllWithMessage('whatsapp')">
                        <i class="fa fa-whatsapp"></i>
                        واتس ذكي
                    </b-dropdown-item>
                    <b-dropdown-item @click="acceptAllWithMessage('sms')">
                        <i class="fa fa-comment"></i>
                        رسالة نصية
                    </b-dropdown-item>
                </b-dropdown>
                <div class="col-12 col-lg-4 g">
                    <div class="form-group">
                    <label for="">عرض تاريخ معين</label>
                    <input type="date" v-model="date"
                        class="form-control">
                    </div>
                </div>
                <table class="table table-hover table-bordered table-sm" id="table">
                    <thead>
                        <th>
                            الطالب
                        </th>
                        <th>
                            الفصل
                        </th>
                        <th>
                            التاريخ
                        </th>
                        <th>
                            العذر
                        </th>
                        <th>
                            تاريخ الرد
                        </th>
                        <th>
                            الحالة
                        </th>
                    </thead>
                    <tbody>
                        <template v-for="(reason) in reasons">
                        <tr :key="reason.name" v-if="date == '' || date == reason.date">
                            <td>
                                {{ reason.student.name }}
                            </td>
                            <td>
                                {{ reason.student.classname }} - {{ reason.student.classroom }}
                            </td>
                            <td>
                                {{ reason.date }}
                            </td>
                            <td>
                                <textarea style="border:none; width: 100%; min-width: 150px" class="hidemonprint" :rows="reason.reason.split('\n').length" v-model="reason.reason" readonly></textarea>
                                <span class="dnone">{{ reason.reason }}</span>
                                <span v-if="reason.file && reason.file != ''">
                                    <br>
                                    <a :href="reason.file" target="_blank" class="hidemonprint"><i class="fa fa-file"></i> عرض المرفق</a>
                                    <a :href="reason.file" target="_blank" class="dnone">تم تضمين مرفق</a>
                                </span>
                            </td>
                            <td>
                                {{ reason.time }}
                            </td>
                            <td>
                                <span>
                                    <span v-if="!reason.edit">
                                        <span v-if="reason.status == 0" class="btn btn-sm btn-warning" style="border-radius: 0px">في الانتظار</span>
                                        <span v-if="reason.status == 1" class="btn btn-sm btn-success" style="border-radius: 0px">مقبول</span>
                                        <span v-if="reason.status == 2" class="btn btn-sm btn-danger" style="border-radius: 0px">مرفوض</span>
                                    </span>
                                    <span v-if="reason.edit">
                                        <select v-model="reason.status" @change="edit(reason)">
                                            <option :value="0">في الانتظار</option>
                                            <option :value="1">قبول العذر</option>
                                            <option :value="2">رفض العذر</option>
                                        </select>
                                    </span>
                                    <button  style="border-radius: 0px" class="btn btn-primary btn-sm hidemonprint" @click="reason.edit = !reason.edit">
                                        <i class="fa fa-edit"></i>
                                    </button>
                                    <b-dropdown class="hidemonprint" dropbottom no-caret v-if="reason.status == 2"
                                        id="dropdown-1" style="border-radius: 0px"
                                        :html="`<i class='fa fa-whatsapp'></i> ارسال رسالة بالرفض` "
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        size="sm"
                                        :variant="reason.sent || sent.includes(reason._id) ? 'secondary' : 'danger'"
                                    >
                                        <div class="input-group">
                                            <span class="input-group-text btn btn-sm btn-primary btn-block" style="padding: 2px; border-radius: 0px" @click="addRejectReason()"><i class="fa fa-plus"></i> اضافة سبب</span>
                                            <select id="select_reject_reason" style="width: 100%" v-model="select_reject_reason">
                                                <option value="">-- اختر السبب --</option>
                                                <option v-for="r in reject_reasons" :value="r" :key="r">{{ r }}</option>
                                            </select>
                                        </div>
                                        <b-dropdown-item @click="sendWAModal([{phones: reason.student.phones, message: generateAlertMessage(reason)}]); sent = [reason._id]">
                                            <i class="fa fa-whatsapp"></i>
                                            اداة المتصفح
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="send([{phones: reason.student.phones, message: generateAlertMessage(reason)}], 'whatsapp'); sent = [reason._id]">
                                            <i class="fa fa-whatsapp"></i>
                                            واتس ذكي
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="send([{phones: reason.student.phones, message: generateAlertMessage(reason)}], 'sms'); sent = [reason._id]">
                                            <i class="fa fa-comment"></i>
                                            رسالة نصية
                                        </b-dropdown-item>
                                    </b-dropdown>
                                    <b-dropdown class="hidemonprint" dropbottom no-caret v-if="reason.status == 1"
                                        id="dropdown-1" style="border-radius: 0px"
                                        :html="`<i class='fa fa-whatsapp'></i> ارسال رسالة بالقبول` "
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        size="sm"
                                        :variant="reason.sent  || sent.includes(reason._id)  ? 'secondary' : 'success'"
                                    >
                                        <b-dropdown-item @click="sendWAModal([{phones: reason.student.phones, message: generateAlertMessage2(reason)}]); sent = [reason._id]">
                                            <i class="fa fa-whatsapp"></i>
                                            اداة المتصفح
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="send([{phones: reason.student.phones, message: generateAlertMessage2(reason)}], 'whatsapp'); sent = [reason._id]">
                                            <i class="fa fa-whatsapp"></i>
                                            واتس ذكي
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="send([{phones: reason.student.phones, message: generateAlertMessage2(reason)}], 'sms'); sent = [reason._id]">
                                            <i class="fa fa-comment"></i>
                                            رسالة نصية
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </span>
                            </td>
                        </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { BModal, VBModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BDropdown,
        BDropdownItem,
        BModal
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            date: new Date().toISOString().split("T")[0],
            reasons: [],
            user: JSON.parse(localStorage.getItem('user')),
            date: "",
            sent: [],
            select_reject_reason: "",
            reject_reasons: JSON.parse(localStorage.getItem("reject_reasons") ?? '[]')
        }
    },
    created(){
        if(!checkPer("students|reasons")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        g.getReport()
    },
    methods: {
        getReport(){
            var g = this;
            $.post(api + '/user/students/reports/reasons', {
                jwt: this.user.jwt
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.reasons = r.response
                }
            }).fail(function(){
            })
        },
        print(){
            var divToPrint=document.getElementById("table");
            var newWin= window.open("");
            newWin.document.write(`
            <style>
            html, body{
                direction: rtl
            }
            .hidemonprint{
                display:none;   
            }
            table, td, th {
            border: 1px solid;
            }

            table {
            width: 100%;
            border-collapse: collapse;
            }
            </style>
            ` + divToPrint.outerHTML);
            newWin.document.close()
            newWin.print();
        },
        addRejectReason(){
            var g = this;
            var p = prompt("اكتب السبب المراد اضافته", "");
            if(p && p != ""){
                g.reject_reasons.push(p);
                localStorage.setItem("reject_reasons", JSON.stringify(g.reject_reasons))
                f.select_reject_reason = p;
            }
        },
        edit(r){
            var g = this;
            $.post(api + '/user/students/reports/reasons-edit', {
                jwt: this.user.jwt,
                id: r._id,
                status: r.status,
                date: r.date,
                number: r.number,
                reason: r.reason,
                name: r.student.name
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.getReport()
                }
            }).fail(function(){
            })
        },
        countPending(){
            return this.reasons.filter(function(x){
                if(x.status == 0){
                    return x;
                }else{
                    return false
                }
            }).length
        },
        acceptAll(){
            var g = this;
            if(confirm("متأكد من الموافقة على الكل؟")){
                // $.post(api + '/user/students/reports/reasons-accept-all', {
                //     jwt: this.user.jwt
                // }).then(function(r){
                //     g.loading = false
                //     if(r.status != 100){
                //         alert(r.response)
                //     }else{
                //         g.getReport()
                //     }
                // }).fail(function(){
                // })
                g.reasons.forEach(function(r){
                    if(r.status == '0'){
                        $.post(api + '/user/students/reports/reasons-edit', {
                                jwt: g.user.jwt,
                                id: r._id,
                                status: '1',
                                date: r.date,
                                number: r.number,
                                reason: r.reason,
                                name: r.student.name
                            }).then(function(r){
                                g.loading = false
                                if(r.status != 100){
                                    alert(r.response)
                                }else{
                                    g.getReport()
                                }
                            }).fail(function(){
                            })
                    }
                })
            }
        },
        acceptAllWithMessage(method){
            var g = this;
            if(confirm("متأكد من الموافقة على الكل وارسال رسائل لهم؟")){
                var arr = [];
                g.sent = [];
                g.reasons.forEach(function(r){
                    if(r.status == '0'){
                        g.sent.push(r._id);
                    }
                })
                g.reasons.forEach(function(r){
                    if(r.status == '0'){
                    g.sent.push(r._id);
                    $.post(api + '/user/students/reports/reasons-edit', {
                            jwt: g.user.jwt,
                            id: r._id,
                            status: '1',
                            date: r.date,
                            number: r.number,
                            reason: r.reason,
                            name: r.student.name
                        }).then(function(r){
                            g.loading = false
                            if(r.status != 100){
                                alert(r.response)
                            }else{
                                g.getReport()
                            }
                        }).fail(function(){
                        })
                        arr.push({
                            phones: r.student.phones, message: g.generateAlertMessage2(r)
                        })
                    }
                })
                if(method != "wa"){
                    g.send(arr, method)
                }else{
                    g.sendWAModal(arr)
                }
            }
        },
        send(arr, method){
            var g = this;
            if(!confirm(`متأكد من ارسال ${arr.length} رسالة؟`)){return false;}
            $.post(api + '/user/messages/send-arr', {
                jwt: this.user.jwt,
                arr: JSON.stringify(arr),
                method: method,
                type: 'students'
            }).then(function(r){
                g.loaded = true;
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    $.post(api + '/user/students/reports/reasons-sent', {
                        jwt: g.user.jwt,
                        ids: JSON.stringify(g.sent),
                    }).then(function(r){
                    })
                    alert("تم الارسال بنجاح", 100)
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        },
        generateAlertMessage(reason){
            var g = this;
            
            if(g.select_reject_reason && g.select_reject_reason != ""){
                var r = g.select_reject_reason
            }else{
                var r = prompt("سبب الرفض (اختياري)", "")
            }
            var message = `تم رفض العذر ({reason})\nالخاص بابنكم {name}\n${r ? 'سبب الرفض: ' + r : ''}`;
            message = message?.replace("{name}", reason.student.name)?.replace("{reason}", reason.reason)
            return message
        },
        generateAlertMessage2(reason){
            var g = this;
            var message = `تم قبول العذر ({reason})\nالخاص بابنكم {name}`;
            message = message?.replace("{name}", reason.student.name)?.replace("{reason}", reason.reason)
            return message
        },
        sendWAModal(arr){
            var g = this;
            $.post(api + '/user/students/reports/reasons-sent', {
                jwt: g.user.jwt,
                ids: JSON.stringify(g.sent),
            }).then(function(r){
            })
            $("#sendmodalwaarray").val(JSON.stringify(arr))
            $("#sendmodalwatype").val("students")
            $("#sendWAModal").click()
        },
    }
}
</script>

<style>

</style>